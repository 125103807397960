import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Text,
  Box,
  Heading,
  Stack,
  Flex,
} from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
import { useCouponCode } from '@modules/checkout/components/Step2/CouponCode/hooks/useCouponCode'
import { useCart } from '@modules/commercetools'

interface CartPromotionAccordionProps {
  isOpen?: boolean
  showDiscount?: JSX.Element
  hideBorderTop?: boolean
  children: JSX.Element
  discount?: number
}

export const CartPromotionAccordion = ({
  isOpen,
  showDiscount,
  hideBorderTop = false,
  children,
  discount,
}: CartPromotionAccordionProps) => {
  const intl = useIntl()
  const { handleCheckDiscount } = useCouponCode()
  const { cart } = useCart()

  const [currentCode] = cart.discountCodes ?? [undefined]

  return (
    <Accordion
      allowToggle
      defaultIndex={isOpen ? [0] : undefined}
      borderColor="transparent"
    >
      <AccordionItem borderTop={hideBorderTop ? 'none' : ''}>
        {({ isExpanded }) => (
          <Box>
            <Heading as="h2">
              <AccordionButton
                padding={0}
                _hover={{ backgroundColor: 'transparent' }}
              >
                <Flex
                  alignItems="center"
                  _hover={{
                    '& > *': {
                      color: 'teal.700',
                    },
                  }}
                  _active={{
                    '& > *': {
                      color: 'primary',
                    },
                  }}
                >
                  <Text as="span" color="primary" marginRight={2}>
                    {isExpanded ? <AiOutlineMinus /> : <AiOutlinePlus />}
                  </Text>
                  <Text
                    flex="1"
                    textAlign="left"
                    color="primary"
                    fontWeight="semibold"
                    size="md"
                  >
                    {isExpanded
                      ? intl.formatMessage({
                          id: 'cart.summary.hideCouponCode',
                        })
                      : handleCheckDiscount(Boolean(currentCode))}
                  </Text>
                </Flex>
              </AccordionButton>
            </Heading>
            <AccordionPanel paddingInline="1px" paddingBottom={0}>
              <Stack spacing="2">
                {showDiscount}
                {children}
              </Stack>
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  )
}
