import {
  Flex,
  IconProps,
  PlacementWithLogical,
  Tooltip,
} from '@chakra-ui/react'
import { Info } from '../../../ui/icons/Info'

interface InfoBoxProps {
  ariaLabel?: string
  iconProps?: IconProps
  label: string
  placement?: PlacementWithLogical
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  ariaLabel,
  iconProps,
  label,
  placement,
}) => {
  return (
    <Tooltip
      aria-label={ariaLabel}
      hasArrow
      label={label}
      openDelay={350}
      placement={placement || 'bottom'}
      variant="dark"
    >
      <Flex tabIndex={0}>
        <Info {...iconProps} />
      </Flex>
    </Tooltip>
  )
}
