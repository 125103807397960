import PREFERENCES from '@modules/app/intl/user-preferences/preferences.json'

export const getCountryLabel = (value: string) => {
  return PREFERENCES.countries.find((country) => country.value === value)?.label
}

export type CountryState = {
  label: string
  value: string
}

export const getCountryStates = (value: string): CountryState[] | undefined => {
  const states = PREFERENCES.countries.find(
    (country) => country.value === value
  )?.states
  const collator = new Intl.Collator()
  return states?.sort((a, b) => collator.compare(a.label, b.label))
}

/**
 * Returns a list that is sorted by first letter of label,
 * and contains both label and value. The label is a combination of
 * the country label and the country value, i.e.: 'Australia - AU'
 * @returns {object[]} i.e.: [{value: 'AU', label: 'Australia - AU'}, ...]
 * */
export const getCountriesAsSelectOptions = () => {
  return PREFERENCES.countries
    .map((country) => ({
      value: country.value,
      label: `${country.label} - ${country.value}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const getCountryAsSelectOption = (country: string) => {
  const countries = getCountriesAsSelectOptions()

  return countries.find((c) => c.value === country)
}

/**
 * Returns abbreviations for countries, containing state
 * * @returns {string[]} i.e.: ['AU', 'CA', 'US']
 *  * */
export const getCountriesContainingStates = () => {
  return PREFERENCES.countries
    .filter((country) => country.states)
    .reduce((acc, country) => {
      acc.push(country.value)
      return acc
    }, [] as string[])
}

export const afterpayCredentialsBase64 = (credentialsBuffer: Buffer) =>
  credentialsBuffer.toString('base64')

export const getCurrencySymbol = (currency: string) => {
  return PREFERENCES.currencies.find((c) => c.value === currency)?.label
}
