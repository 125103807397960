import { Icon, IconProps } from '@chakra-ui/react'

export const MasterCard = (props: IconProps) => (
  <Icon
    fill="none"
    height="16px"
    viewBox="0 0 24 16"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="mastercard-logo"
    {...props}
  >
    <title id="mastercard-logo">Mastercard logo</title>
    <path
      d="M23.0095 0H0.990467C0.443447 0 0 0.443447 0 0.990467V15.0095C0 15.5566 0.443447 16 0.990467 16H23.0095C23.5566 16 24 15.5566 24 15.0095V0.990467C24 0.443447 23.5566 0 23.0095 0Z"
      fill="white"
    />

    <path
      d="M10.0586 4.06848H14.0976V12.6206H10.0586V4.06848Z"
      fill="#FF5F00"
    />

    <path
      d="M10.1687 8.34501C10.1671 7.53757 10.3379 6.7402 10.6683 6.01247C10.9987 5.28475 11.4801 4.64552 12.0767 4.14254C11.0927 3.31241 9.84721 2.90996 8.59314 3.01695C7.33907 3.12393 6.17047 3.73232 5.3247 4.71854C4.47233 5.71072 4 7.00377 4 8.34501C4 9.68626 4.47233 10.9793 5.3247 11.9715C6.17047 12.9577 7.33907 13.5661 8.59314 13.6731C9.84721 13.7801 11.0927 13.3776 12.0767 12.5475C11.4801 12.0445 10.9987 11.4053 10.6683 10.6776C10.3379 9.94983 10.1671 9.15245 10.1687 8.34501Z"
      fill="#EB001B"
    />

    <path
      d="M20.1541 8.34504C20.1563 9.34417 19.8942 10.324 19.3976 11.1735C18.901 12.0231 18.1897 12.7085 17.3441 13.1521C16.5024 13.5904 15.5603 13.7663 14.6278 13.6593C13.6952 13.5522 12.8106 13.1666 12.0771 12.5475C12.6731 12.0438 13.1541 11.4042 13.4842 10.6765C13.8144 9.94866 13.9853 9.15138 13.9841 8.34398C13.985 7.53694 13.814 6.74008 13.4838 6.01268C13.1536 5.28528 12.6728 4.64606 12.0771 4.14257C12.8106 3.52344 13.6952 3.13787 14.6278 3.03082C15.5603 2.92377 16.5024 3.09967 17.3441 3.53798C18.1897 3.9815 18.9011 4.66686 19.3978 5.51645C19.8944 6.36603 20.1564 7.34589 20.1541 8.34504Z"
      fill="#F79E1B"
    />
  </Icon>
)
