import {
  FormControl,
  FormControlProps,
  FormLabel,
  IconButton,
  IconButtonProps,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useNumberInput,
  UseNumberInputProps,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { useIntl } from 'react-intl'

interface QuantityPickerProps extends UseNumberInputProps {
  max?: number
  min?: number
  rootProps?: FormControlProps
  isLoading?: boolean
  hideLabel?: boolean
  label?: string
  buttonProps?: Partial<IconButtonProps>
  isEditable?: boolean
}

export const QuantityPicker = (props: QuantityPickerProps) => {
  const {
    min = 0,
    max,
    rootProps,
    isDisabled,
    isLoading,
    hideLabel,
    label = 'Quantity',
    buttonProps,
    onChange,
    value,
    isEditable = true,
    ...rest
  } = props
  const intl = useIntl()
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      max,
      min,
      onChange,
      allowMouseWheel: true,
      defaultValue: value,
      isDisabled: isDisabled ?? isLoading,
    })
  const incrementProps = getIncrementButtonProps()
  const decrementProps = getDecrementButtonProps()
  const inputProps = getInputProps()

  return (
    <FormControl aria-label={`${label} selected is ${value}`} {...rootProps}>
      {hideLabel ? (
        <VisuallyHidden>
          <FormLabel>{label}</FormLabel>
        </VisuallyHidden>
      ) : (
        <FormLabel fontSize="sm" fontWeight="medium">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        {isEditable && (
          <InputLeftAddon padding="0" border="none">
            <IconButton
              {...decrementProps}
              aria-label={intl.formatMessage({ id: 'cart.item.decrement' })}
              borderRightRadius="none"
              icon={<FiMinus />}
              width={{ base: '36px', lg: '48px' }}
              variant="quantity-picker-addon"
            />
          </InputLeftAddon>
        )}

        {isEditable && (
          <Input
            {...inputProps}
            textAlign="center"
            padding="xs"
            maxLength={3}
            zIndex="1"
          />
        )}

        {isEditable && (
          <InputRightAddon padding="0" border="none">
            <IconButton
              {...incrementProps}
              aria-label={intl.formatMessage({ id: 'cart.item.increment' })}
              borderLeftRadius="none"
              icon={<FiPlus />}
              width={{ base: '36px', lg: '48px' }}
              variant="quantity-picker-addon"
            />
          </InputRightAddon>
        )}
      </InputGroup>
    </FormControl>
  )
}
