import { useIntl } from 'react-intl'

export const useCouponCode = () => {
  const intl = useIntl()

  const handleCheckDiscount = (discount: boolean | undefined) => {
    return discount
      ? intl.formatMessage({
          id: 'cart.summary.showCouponCode',
        })
      : intl.formatMessage({
          id: 'cart.summary.addCouponCode',
        })
  }

  return {
    handleCheckDiscount,
  }
}
