import { Icon, IconProps } from '@chakra-ui/react'

export const Visa: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    height="16px"
    viewBox="0 0 24 16"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="visa-logo"
    {...props}
  >
    <title id="visa-logo">Visa logo</title>
    <path
      d="M23.0095 0H0.990467C0.443447 0 0 0.443447 0 0.990467V15.0095C0 15.5566 0.443447 16 0.990467 16H23.0095C23.5566 16 24 15.5566 24 15.0095V0.990467C24 0.443447 23.5566 0 23.0095 0Z"
      fill="#0E4595"
    />

    <path
      d="M14.8065 4.99988C15.4206 4.99988 15.9118 5.12666 16.2258 5.24452L16.0109 6.5044L15.8692 6.43705C15.4938 6.28115 15.09 6.20525 14.6837 6.21419C14.0636 6.21419 13.7764 6.47171 13.7764 6.71438C13.7724 6.98675 14.1122 7.16603 14.6658 7.43445C15.58 7.85044 16.002 8.35261 15.996 9.01424C15.9832 10.2206 14.9026 11.0001 13.2366 11.0001C12.5264 10.9922 11.842 10.8516 11.4716 10.6901L11.6944 9.38864L11.8985 9.48075C12.4185 9.69865 12.7552 9.78581 13.3901 9.78581C13.8457 9.78581 14.334 9.60753 14.338 9.21828C14.341 8.96373 14.133 8.78247 13.5159 8.49722C12.9157 8.21989 12.1193 7.75437 12.1292 6.91841C12.1382 5.78829 13.2406 4.99988 14.8065 4.99988ZM11.4934 5.11873L10.5029 10.9318H8.91815L9.90862 5.11873H11.4934ZM20.3036 5.11873L21.5852 10.9278H20.1164C20.1164 10.9278 19.9708 10.2602 19.9232 10.0562L17.8948 10.0542C17.8333 10.2117 17.561 10.9278 17.561 10.9278H15.897L18.2503 5.60109C18.4167 5.22174 18.701 5.11873 19.0793 5.11873H20.3036ZM4.57496 5.11873C4.91865 5.13161 5.19697 5.24155 5.29304 5.60902L5.83285 8.20305L5.85662 8.27733L6.02203 9.08258L7.5731 5.11873H9.25194L6.75695 10.9199L5.08108 10.9219L3.67462 5.88337C3.14833 5.59407 2.58524 5.3775 2.00073 5.23957L2.02054 5.11873H4.57496ZM19.2022 6.69259C19.1219 6.91049 18.9823 7.26309 18.9922 7.24725C18.9922 7.24725 18.492 8.53683 18.3603 8.87161H19.6756L19.3091 7.19376L19.2022 6.69259Z"
      fill="white"
    />
  </Icon>
)
